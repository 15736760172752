import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Table } from 'reactstrap';

export const GET_POSTS = gql`
  query GetCameraData {
    cameraData {
      camera_id
      name
      last_updated
    } 
  }
`;

export default () => (
  <Query query={GET_POSTS}>
    {({ loading, data }) => !loading && (
      <Table>
        <thead>
          <tr>
            <th>lastUpdated</th>
            <th>CameraName</th>
          </tr>
        </thead>
        <tbody>
          {data.cameraData.map(post => (
            <tr key={post.camera_id}>
              <td>{post.last_updated}</td>
              <td>{post.name}</td>
              <img src={require("./images/"+post.camera_id+".png")}/>
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </Query>
);